import { FaSnapchat, FaInstagram, FaWhatsapp, FaTiktok, FaPhoneAlt } from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/yannicaffee',
  },
  {
    id: 2,
    text: "تيك توك",
    icon: FaTiktok,
    href: "https://www.tiktok.com/@yannicoffee",
  },
  {
    id: 3,
    text: 'إنستجرام',
    icon: FaInstagram,
    href: 'https://www.instagram.com/yannicaffee/',
  },
  {
    id: 4,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966552618981',
  },
  { id: 5, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0552618981' },
]
export const data = [
   {
        id: 1,
        title: 'القهوة الساخنة',
        image: '../../../img/list/coffe-cup.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'سبانش لاتيه',
                description: '',
                image: '../../img/food/span.jpg',
                price: 20,
                calories: 209,
                selected: false,
            },
            {
                id: 2,
                title: 'بستاشيو لاتيه',
                description: '',
                image: '../../img/food/pstachio.jpg',
                price: 22,
                calories: 180,
                selected: false,
            },
            {
                id: 3,
                title: 'زعفران لاتيه',
                description: '',
                image: '../../img/food/zafran.jpg',
                price: 22,
                calories: 206,
                selected: true,
            },
            {
                id: 4,
                title: 'كابتشينو',
                description: '',
                image: '../../img/food/capatchino.jpg',
                price: 18,
                calories: 144,
                selected: true,
            },
            {
                id: 5,
                title: 'لاتية',
                description: '',
                image: '../../img/food/late.jpg',
                price: 18,
                calories: 151,
                selected: true,
            },
            {
                id: 6,
                title: 'فلات وايت',
                description: '',
                image: '../../img/food/falt.jpg',
                price: 16,
                calories: 131,
                selected: true,
            },
            {
                id: 7,
                title: 'موكا',
                description: '',
                image: '../../img/food/moka.jpg',
                price: 22,
                calories: 215,
                selected: true,
            },
            {
                id: 8,
                title: 'أمريكانو',
                description: '',
                image: '../../img/food/amrico.jpg',
                price: 15,
                calories: 12,
                selected: true,
            },
            {
                id: 9,
                title: 'ماكيتو',
                description: '',
                image: '../../img/food/maciato.jpg',
                price: 15,
                calories: 90,
                selected: true,
            },
            {
                id: 10,
                title: 'إطاليانو',
                description: '',
                image: '../../img/food/italiano.jpg',
                price: 22,
                calories: 189,
                selected: true,
            },
            {
                id: 11,
                title: 'كورتادو',
                description: '',
                image: '../../img/food/cortado.jpg',
                price: 18,
                calories: 162,
                selected: true,
            },
            {
                id: 12,
                title: 'إسبرسو',
                description: '',
                image: '../../img/food/ispressop.jpg',
                price: 12,
                calories: 4,
                selected: true,
            },
            {
                id: 13,
                title: 'دبل إسبرسو',
                description: '',
                image: '../../img/food/ispressop.jpg',
                price: 16,
                calories: 8,
                selected: true,
            },
            {
                id: 14,
                title: 'قهوة تركية',
                description: '',
                image: '../../img/food/turkish.jpg',
                price: 12,
                calories: 5,
                selected: true,
            },
            {
                id: 14,
                title: 'قهوة تركية دبل',
                description: '',
                image: '../../img/food/turkish.jpg',
                price: 16,
                calories: 5,
                selected: true,
            },
            {
                id: 15,
                title: 'قهوة فرنسية',
                description: '',
                image: '../../img/food/frnch.jpg',
                price: 22,
                calories: 7,
                selected: true,
            },
            {
                id: 16,
                title: 'دلة قهوة سعودية',
                description: '',
                image: '../../img/food/dlah.jpg',
                price: 32,
                calories: 6,
                selected: true,
            },
        ]
    },
    {
        id: 2,
        title: 'المشروبات الباردة',
        image: '../../../img/list/bard.png',
        selected: false,
        items: [
            {
                id: 17,
                title: 'ايس سبانش لاتيه',
                description: '',
                image: '../../img/food/spanish.jpg',
                price: 23,
                calories: 206,
                selected: false,
            },
            {
                id: 18,
                title: 'ايس بستاشيو لاتيه',
                description: '',
                image: '../../img/food/bstachio.jpg',
                price: 23,
                calories: 250,
                selected: false,
            },
            {
                id: 19,
                title: 'ايس زعفران لاتيه',
                description: '',
                image: '../../img/food/saffron.jpg',
                price: 23,
                calories: 250,
                selected: true,
            },
            {
                id: 20,
                title: 'ايس كراميل لاتيه',
                description: '',
                image: '../../img/food/caramel.jpg',
                price: 23,
                calories: 206,
                selected: true,
            },
            {
                id: 21,
                title: 'ايس فانيلا لاتيه',
                description: '',
                image: '../../img/food/fanila.jpg',
                price: 23,
                calories: 227,
                selected: true,
            },
            {
                id: 22,
                title: ' ايس موكا لاتية',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 23,
                calories: 240,
                selected: true,
            },
            {
                id: 23,
                title: 'ايس موكا',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 23,
                calories: 250,
                selected: true,
            },
            {
                id: 24,
                title: 'ايس أمريكانو',
                description: '',
                image: '../../img/food/iceamricano.jpg',
                price: 23,
                calories: 15,
                selected: true,
            },
            {
                id: 25,
                title: 'ايس ريد فيلفيت',
                description: '',
                image: '../../img/food/redf.jpeg',
                price: 23,
                calories: 204,
                selected: true,
            },
            {
                id: 27,
                title: 'ايس شوكلت',
                description: '',
                image: '../../img/food/iceshoc.jpeg',
                price: 23,
                calories: 214,
                selected: true,
            },
            {
                id: 28,
                title: 'ايس إيطاليانو',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 23,
                calories: 227,
                selected: true,
            },
            {
                id: 29,
                title: 'ايس توفي لاتيه',
                description: '',
                image: '../../img/food/caramel.jpg',
                price: 20,
                calories: 246,
                selected: true,
            },
        ]
    },
    {
        id: 3,
        title: 'القهوة المختصة',
        image: '../../../img/list/mo5.png',
        selected: false,
        items: [
            {
                id: 31,
                title: 'فرنش بريس',
                description: '',
                image: '../../img/food/frnch.jpeg',
                price: 23,
                calories: 0,
                selected: false,
            },
            {
                id: 32,
                title: 'كيمكس',
                description: '',
                image: '../../img/food/kimks.jpeg',
                price: 23,
                calories: 0,
                selected: false,
            },
            {
                id: 33,
                title: 'V60',
                description: '',
                image: '../../img/food/v60.jpeg',
                price: 23,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 4,
        title: 'الموهيتو',
        image: '../../../img/list/mohi.png',
        selected: false,
        items: [
            {
                id: 34,
                title: 'رد بيري',
                description: '',
                image: '../../img/food/redperry.jpg',
                price: 21,
                calories: 428,
                selected: false,
            },
            {
                id: 35,
                title: 'بلو بيري',
                description: '',
                image: '../../img/food/02a6.jpg',
                price: 21,
                calories: 319,
                selected: false,
            },
            {
                id: 36,
                title: 'روز بيري',
                description: '',
                image: '../../img/food/redperry.jpg',
                price: 21,
                calories: 439,
                selected: true,
            },
            {
                id: 37,
                title: 'سبيشل موهيتو',
                description: '',
                image: '../../img/food/specialmohito.jpeg',
                price: 23,
                calories: 435,
                selected: true,
            },
            {
                id: 335,
                title: 'موهيتو فراولة',
                description: '',
                image: '../../img/food/50.jpg',
                price: 21,
                calories: 310,
                selected: true,
            },
            {
                id: 336,
                title: 'موهيتو كلاسك',
                description: '',
                image: '../../img/food/032.jpg',
                price: 21,
                calories: 310,
                selected: true,
            },
            {
                id: 337,
                title: 'موهيتو باشن كركدية',
                description: '',
                image: '../../img/food/72.jpg',
                price: 21,
                calories: 310,
                selected: true,
            },
            {
                id: 338,
                title: 'موهيتو باشن',
                description: '',
                image: '../../img/food/e1.jpg',
                price: 21,
                calories: 310,
                selected: true,
            },
            {
                id: 339,
                title: 'موهيتو بلو اوشن',
                description: '',
                image: '../../img/items.png',
                price: 21,
                calories: 320,
                selected: true,
            },
        ]
    },
    {
        id: 5,
        title: 'الشاي',
        image: '../../../img/list/tea.png',
        selected: false,
        items: [
            {
                id: 38,
                title: 'شاي احمر',
                description: 'كوب - براد',
                image: '../../img/food/shahya7mr.png',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 39,
                title: 'شاي اخضر',
                description: 'كوب - براد',
                image: '../../img/food/shahya5dr.png',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 40,
                title: 'شاي مغربي',
                description: 'كوب - براد',
                image: '../../img/food/mghrbi.png',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 41,
                title: 'شاي يانسون',
                description: 'كوب - براد',
                image: '../../img/food/yansuon.jpeg',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 42,
                title: 'شاي بابونج',
                description: 'كوب - براد',
                image: '../../img/food/babonj.jpeg',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 43,
                title: 'كركديه',
                description: 'كوب - براد',
                image: '../../img/food/krkadia.jpg',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 44,
                title: 'شاي توت',
                description: 'كوب - براد',
                image: '../../img/food/tea-tot.jpg',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 330,
                title: 'شاي رمان',
                description: 'كوب - براد',
                image: '../../img/items.png',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 331,
                title: 'شاي تلقيمة',
                description: 'كوب - براد',
                image: '../../img/items.png',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
            {
                id: 332,
                title: 'كرك',
                description: 'كوب - براد',
                image: '../../img/food/6c8.jpg',
                price: 10,
                calories: 0,
                selected: false,
                size: {
                    small: 10,
                    large: 25,
                  },
            },
        ]
    },
    {
        id: 6,
        title: 'الحلا',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 45,
                title: 'تربيل شوكلت',
                description: '',
                image: '../../img/food/57.jpg',
                price: 30,
                calories: 380,
                selected: false,
            },
            {
                id: 46,
                title: 'تيراميسو',
                description: '',
                image: '../../img/food/e5c.jpg',
                price: 30,
                calories: 346,
                selected: false,
            },
            {
                id: 47,
                title: 'كنافه تشيز كيك',
                description: '',
                image: '../../img/food/474a.jpg',
                price: 30,
                calories: 318,
                selected: true,
            },
            {
                id: 48,
                title: 'ريد فلفت',
                description: '',
                image: '../../img/food/d7f.jpg',
                price: 28,
                calories: 370,
                selected: true,
            },
            {
                id: 49,
                title: 'وافل',
                description: '',
                image: '../../img/food/وافل_24.jpg',
                price: 23,
                calories: 505,
                selected: true,
            },
            {
                id: 50,
                title: 'كيك روشية',
                description: '',
                image: '../../img/food/0c2.jpg',
                price: 30,
                calories: 344,
                selected: true,
            },
            {
                id: 51,
                title: 'كيك العسل',
                description: '',
                image: '../../img/food/9يd.jpg',
                price: 30,
                calories: 298,
                selected: true,
            },
            {
                id: 52,
                title: 'سان سبيستيان',
                description: '',
                image: '../../img/food/6d500.jpg',
                price: 30,
                calories: 388,
                selected: true,
            },
            {
                id: 53,
                title: 'كيك التمر',
                description: '',
                image: '../../img/food/0f0c.jpg',
                price: 30,
                calories: 310,
                selected: true,
            },
            {
                id: 54,
                title: 'ميني بان كيك',
                description: '',
                image: '../../img/food/بان-كيك_2.jpg',
                price: 30,
                calories: 455,
                selected: true,
            },
            {
                id: 55,
                title: 'تشيز كيك بلو بيري',
                description: '',
                image: '../../img/food/cheez.png',
                price: 30,
                calories: 328,
                selected: true,
            },
            {
                id: 56,
                title: 'زعفران تشيز كيك',
                description: '',
                image: '../../img/food/806.jpg',
                price: 28,
                calories: 318,
                selected: true,
            },
            {
                id: 308,
                title: 'كيك زعفران',
                description: '',
                image: '../../img/food/كيك-الزعفران_22.jpg',
                price: 28,
                calories: 310,
                selected: true,
            },
        ]
    },
    {
        id: 7,
        title: 'العصائر',
        image: '../../../img/list/juc.png',
        selected: false,
        items: [
            {
                id: 57,
                title: 'سندريلا',
                description: '',
                image: '../../img/food/سندريلا_15.jpg',
                price: 25,
                calories: 115,
                selected: false,
            },
            {
                id: 58,
                title: 'جامايكا',
                description: '',
                image: '../../img/food/سندريلا_15.jpg',
                price: 25,
                calories: 108,
                selected: false,
            },
            {
                id: 59,
                title: 'برتقال',
                description: '',
                image: '../../img/food/برتقال_5.jpg',
                price: 19,
                calories: 55,
                selected: true,
            },
            {
                id: 60,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/food/ليمون.jpg',
                price: 25,
                calories: 240,
                selected: true,
            },
            {
                id: 61,
                title: 'سيجنتشر',
                description: '',
                image: '../../img/food/سجنتشر_14.jpg',
                price: 25,
                calories: 560,
                selected: true,
            },
            {
                id: 62,
                title: 'اناناس',
                description: '',
                image: '../../img/food/انانس.jpg',
                price: 30,
                calories: 150,
                selected: true,
            },
            {
                id: 63,
                title: 'ليمون سادة',
                description: '',
                image: '../../img/food/ليمونساده.jpg',
                price: 25,
                calories: 190,
                selected: true,
            },
            {
                id: 117,
                title: 'رمان',
                description: '',
                image: '../../img/food/rman.jpg',
                price: 22,
                calories: 134,
                selected: true,
            },
            {
                id: 118,
                title: 'مانجو',
                description: '',
                image: '../../img/food/mangonew.jpg',
                price: 24,
                calories: 190,
                selected: true,
            },
            {
                id: 119,
                title: 'شمام حليب',
                description: '',
                image: '../../img/food/shmam-7lib.jpg',
                price: 24,
                calories: 130,
                selected: true,
            },
            {
                id: 120,
                title: 'إكلات سولاي',
                description: '',
                image: '../../img/food/aklitsolay.jpg',
                price: 25,
                calories: 132,
                selected: true,
            },
            {
                id: 121,
                title: 'بطيخ',
                description: '',
                image: '../../img/food/b6y5.jpg',
                price: 24,
                calories: 120,
                selected: true,
            },
            {
                id: 122,
                title: 'بطيخ ليمون',
                description: '',
                image: '../../img/food/b6y5limo.jpg',
                price: 26,
                calories: 82,
                selected: true,
            },
            {
                id: 123,
                title: 'تروبيكال',
                description: '',
                image: '../../img/food/trobkal.jpg',
                price: 30,
                calories: 204,
                selected: true,
            },
            {
                id: 124,
                title: 'افوكادو',
                description: '',
                image: '../../img/food/afokado.jpg',
                price: 30,
                calories: 229,
                selected: true,
            },
            {
                id: 125,
                title: 'ياني ديليس',
                description: '',
                image: '../../img/food/yanidenis.jpg',
                price: 22,
                calories: 140,
                selected: true,
            },
            {
                id: 126,
                title: 'ايرون مان',
                description: '',
                image: '../../img/food/iron-man.jpg',
                price: 25,
                calories: 120,
                selected: true,
            },
        ]
    },
    {
        id: 8,
        title: 'السموزي',
        image: '../../../img/list/smoth.png',
        selected: false,
        items: [
            {
                id: 64,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/food/ليمون.jpg',
                price: 25,
                calories: 102,
                selected: false,
            },
            {
                id: 65,
                title: 'فراولة',
                description: '',
                image: '../../img/food/فراوله.jpg',
                price: 25,
                calories: 108,
                selected: false,
            },
            {
                id: 66,
                title: 'روز بيري',
                description: '',
                image: '../../img/food/redperry.jpg',
                price: 25,
                calories: 108,
                selected: true,
            },
            {
                id: 67,
                title: 'باشن',
                description: '',
                image: '../../img/food/86d7c.jpg',
                price: 23,
                calories: 250,
                selected: true,
            },
            {
                id: 339,
                title: 'مانجو',
                description: '',
                image: '../../img/items.png',
                price: 23,
                calories: 250,
                selected: true,
            },
        ]
    },
    {
        id: 9,
        title: 'مشروبات اخرى',
        image: '../../../img/list/energy.png',
        selected: false,
        items: [
            {
                id: 68,
                title: 'بيبسي',
                description: '',
                image: '../../img/food/pebsi.jpg',
                price: 9,
                calories: 183,
                selected: false,
            },
            {
                id: 69,
                title: 'سفن اب',
                description: '',
                image: '../../img/food/sfn.jpeg',
                price: 9,
                calories: 184,
                selected: true,
            },
            {
                id: 70,
                title: ' ماء معدني',
                description: '',
                image: '../../img/food/ber.jpg',
                price: 8,
                calories: 0,
                selected: true,
            },
            {
                id: 71,
                title: ' ماء فوار',
                description: '',
                image: '../../img/food/foar.jpg',
                price: 14,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 10,
        title: 'الشيشة',
        image: '../../../img/list/shisha.png',
        selected: false,
        items: [
            {
                id: 72,
                title: 'عنب نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: false,
            },
            {
                id: 73,
                title: 'عنب فرنسي',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: false,
            },
            {
                id: 74,
                title: 'ليمون نعناع فاخر',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 75,
                title: 'ليمون نعناع مزايا',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 76,
                title: 'شمام كنتلوب',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 77,
                title: 'بلو بيري',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 78,
                title: 'مستكة نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 79,
                title: 'علكة مستكة',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 80,
                title: 'بطيخ نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 81,
                title: 'علكة ساده',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 82,
                title: 'شيشة تفاحتين فاخر',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 83,
                title: 'مكس',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 84,
                title: 'شيشة تفاحتين نخله',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 85,
                title: 'شيشة ليمون نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 86,
                title: 'شيشة عنب نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 87,
                title: 'شيشة عنب توت',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
            {
                id: 88,
                title: 'شيشة عنب سادة',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 97,
                calories: 0,
                selected: true,
            },
        ]
    }, 
    {
        id: 11,
        title: 'الأطباق الرئيسية',
        image: '../../../img/list/kitchen.png',
        selected: false,
        items: [
            {
                id: 90,
                title: 'سلمون مشوي',
                description: '',
                image: '../../img/food/SALMONGRILLED86.JPG',
                price: 86,
                calories: 350,
                selected: false,
            },
            {
                id: 92,
                title: 'دينمت روبيان',
                image: '../../img/food/دينمت-جمبري_10.jpg',
                price: 54,
                calories: 480,
                selected: false,
            },
            {
                id: 93,
                title: 'تندر مقلي',
                image: '../../img/food/tndr.jpg',
                price: 52,
                calories: 530,
                selected: false,
            },
            {
                id: 94,
                title: 'صحن بطاطس',
                image: '../../img/food/btata.jpg',
                price: 10,
                calories: 0,
                selected: false,
            },
            {
                id: 96,
                title: 'بطاطس هاش براون',
                description: '',
                image: '../../img/food/HASHBROWNPOTATO35.JPG',
                price: 35,
                calories: 140,
                selected: false,
            },
        ]
    },
    {
        id: 12,
        title: 'الشوربات',
        image: '../../../img/list/soop.png',
        selected: false,
        items: [
            {
                id: 97,
                title: 'فطر دجاج بالكريمة',
                description: 'دجاج-كريمة-خبز محمص',
                image: '../../img/food/شوربه-دجاج_18.jpg',
                price: 30,
                calories: 340,
                selected: false,
            },
            {
                id: 98,
                title: 'شوربه الفطر',
                description: 'فطر بني–فطر ابيض -اعشاب إيطالية-كريمة– برموزان',
                image: '../../img/food/shorbaftr.jpg',
                price: 39,
                calories: 160,
                selected: false,
            },
            {
                id: 99,
                title: 'شوربه الدجاج',
                description: '',
                image: '../../img/food/CHICKENSOUP33SR.JPG',
                price: 33,
                calories: 630,
                selected: false,
            },
        ]
    },
    {
        id: 13,
        title: 'السلطات',
        image: '../../../img/list/salat.png',
        selected: false,
        items: [
            {
                id: 101,
                title: 'سيزر',
                description: 'خس طازج -  قطع دجاج مقرمشة  - صلصة  سيزر - خبز محمص -  جبنة برموزان',
                image: '../../img/food/سيزر_17.jpg',
                price: 42,
                calories: 910,
                selected: false,
            },
            {
                id: 102,
                title: 'ياني سلطة',
                description: 'خيار طماطم  - فلفل ملون  - جزر  - مكسرات -  فاصوليا حمراء  - درة صوص شيف',
                image: '../../img/food/ياني-سلطه_25.jpg',
                price: 30,
                calories: 150,
                selected: false,
            },
            {
                id: 103,
                title: 'روكا',
                description: 'أوراق الجرجير الكوبة -  مكسرات  - فطر  - طماطم مجفف -  برموزان -  دبس رومان  - بلزمك',
                image: '../../img/food/روكا-سلطه_12.jpg',
                price: 43,
                calories: 110,
                selected: false,
            },
            {
                id: 104,
                title: 'سلطه استوائيه',
                description: '',
                image: '../../img/food/EXOTICSALADE44.jpg',
                price: 44,
                calories: 300,
                selected: false,
            },
            {
                id: 105,
                title: 'سلطه الجرجير',
                description: '',
                image: '../../img/food/ROCCASALADE37.jpg',
                price: 37,
                calories: 300,
                selected: false,
            },
            {
                id: 106,
                title: 'سلطه السيزر',
                description: '',
                image: '../../img/food/CESARSALADE39SR.JPG',
                price: 45,
                calories: 670,
                selected: false,
            },
            {
                id: 107,
                title: 'سلطه الكينوا',
                description: '',
                image: '../../img/food/QUINOASALADE42.JPG',
                price: 42,
                calories: 350,
                selected: false,
            },
        ]
    },
    {
        id: 14,
        title: 'السندويشات',
        image: '../../../img/list/sandwich.png',
        selected: false,
        items: [
            {
                id: 108,
                title: 'كرسبي دجاج',
                description: 'خبز البريوش  - صدور الدجاج المقرمش -  خس  - طماطم  - خيار  مخلل -  شرائح الجبن -  يقدم مع البطاطس المقرمشة .',
                image: '../../img/food/krspi-djaj.jpg',
                price: 55,
                calories: 720,
                selected: false,
            },
            {
                id: 109,
                title: 'فهيتا دجاج',
                description: '',
                image: '../../img/food/CHICKENFAJITA42.jpg',
                price: 42,
                calories: 660,
                selected: false,
            },
            {
                id: 110,
                title: 'برجر لحم',
                description: 'خبز البريوش -  لحم عجل طازج  - شرائح الجبن  - صلصة خاصة  - طمااطم  - مخلل خيار - خس  - يقدم مع البطاطس المقرمشة',
                image: '../../img/food/BEEFBURGER.JPG',
                price: 50,
                calories: 810,
                selected: false,
            },
            {
                id: 111,
                title: 'برجر دجاج',
                description: 'خبز البريوش -  دجاج طازج  - شرائح الجبن  - صلصة خاصة  - طمااطم  - مخلل خيار - خس  - يقدم مع البطاطس المقرمشة ',
                image: '../../img/food/CHICKENBURGER.JPG',
                price: 48,
                calories: 480,
                selected: false,
            },
            {
                id: 112,
                title: 'فهيتا جمبري',
                description: 'جمبري مطبوخ مع الفلل الملون  - بصل  - جبنة الشيدر -  يقدم مع البطاطس المقرمشة ',
                image: '../../img/food/fahitajmp.jpg',
                price: 56,
                calories: 580,
                selected: false,
            },
            {
                id: 113,
                title: 'كاساديا دجاج',
                description: '',
                image: '../../img/food/CHICKENQUESADILLA39.JPG',
                price: 39,
                calories: 530,
                selected: false,
            },
        ]
    },
    {
        id: 15,
        title: 'البيتزا',
        image: '../../../img/list/pizza.png',
        selected: false,
        items: [
            {
                id: 114,
                title: 'مارغريتا',
                description: 'جبنة الموزريلا  -  ريحان فرش  - صوص الطماطم  - برموزان  - زيت زيتون',
                image: '../../img/food/margrita.jpg',
                price: 45,
                calories: 1360,
                selected: false,
            },
            {
                id: 115,
                title: 'ميديتيرين',
                description: 'خضار مشويه  - صوص الطماطم  - طحينيه  - دبس رومان  - مكسرات',
                image: '../../img/food/ميد.jpg',
                price: 49,
                calories: 0,
                selected: false,
            },
            {
                id: 116,
                title: 'بيستو بيتزا',
                description: 'صوص الريحان -  قطع الدجاج  - طماطم مجففه -  زيتون -  جبنة موزريلا',
                image: '../../img/food/بيستو-بيتزا_7.jpg',
                price: 52,
                calories: 1540,
                selected: false,
            },
        ]
    },
    {
        id: 16,
        title: 'الباستا',
        image: '../../../img/list/pasta.png',
        selected: false,
        items: [
            {
                id: 117,
                title: 'بيستو',
                description: '',
                image: '../../img/food/بيستوا.jpg',
                price: 49,
                calories: 500,
                selected: false,
            },
            {
                id: 118,
                title: 'ترافل',
                description: '',
                image: '../../img/food/ترفل.jpg',
                price: 69,
                calories: 520,
                selected: false,
            },
            {
                id: 119,
                title: 'بينك صوص',
                description: '',
                image: '../../img/food/panksos.jpg',
                price: 49,
                calories: 340,
                selected: false,
            },
            {
                id: 120,
                title: 'مشروم صوص',
                description: '',
                image: '../../img/items.png',
                price: 56,
                calories: 310,
                selected: false,
            },
            {
                id: 121,
                title: 'فوتتشيني  الفريدو',
                description: 'دجاج مشوي  - فطر -  برموزان  - صوص الفريدو',
                image: '../../img/food/fotshinialfrido.jpg',
                price: 59,
                calories: 740,
                selected: false,
            },
            {
                id: 122,
                title: 'ياني باستا',
                description: 'مكرونة الخواتم  - صوص البولونيز  - صوص خاص -  مزريلا  - برموزان',
                image: '../../img/food/yannipasta.jpg',
                price: 67,
                calories: 750,
                selected: false,
            },
            {
                id: 123,
                title: 'بيني ارابياتا',
                description: '',
                image: '../../img/food/PENNEARABIATA41.JPG',
                price: 41,
                calories: 550,
                selected: false,
            },
            {
                id: 124,
                title: 'ريغاطوني ترافل',
                description: '',
                image: '../../img/food/RIGATONNITRUFLLE45.JPG',
                price: 54,
                calories: 860,
                selected: false,
            },
            {
                id: 125,
                title: 'فيتوتشيني الفريدو',
                description: '',
                image: '../../img/food/FETUCCINIALFREDO49.JPG',
                price: 49,
                calories: 650,
                selected: false,
            },
            {
                id: 126,
                title: 'اضافه دجاج',
                description: '',
                image: '../../img/items.png',
                price: 20,
                calories: 0,
                selected: false,
            },
            {
                id: 127,
                title: 'اضافه جمبري',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 0,
                selected: false,
            },
        ]
    },
    {
        id: 17,
        title: 'روزيتو',
        image: '../../../img/list/roz.png',
        selected: false,
        items: [
            {
                id: 128,
                title: 'روزيتو فطر',
                description: 'الماكولات البحرية -  بصل  - ثوم  - طماطم  - ريحان  - كريمة -  جبنة البرومزان ',
                image: '../../img/food/روزيتو-فطر_11.jpg',
                price: 50,
                calories: 820,
                selected: false,
            },
            {
                id: 129,
                title: 'روزيتو زعفران',
                description: 'زعفران  - برموزان  - بصل  - ثوم - كريمة',
                image: '../../img/food/روزيتوزعفران.jpg',
                price: 48,
                calories: 820,
                selected: false,
            },
            {
                id: 130,
                title: 'روزيتو بيستو',
                description: 'صوص البيستو  - كريمة -  برموزان ',
                image: '../../img/food/روزيتوبيستو.jpg',
                price: 50,
                calories: 880,
                selected: false,
            },
            {
                id: 131,
                title: 'روزيتو جمبري',
                description: '',
                image: '../../img/food/روزيتوجمبري.jpg',
                price: 48,
                calories: 880,
                selected: false,
            },
            {
                id: 132,
                title: 'روزيتو ترفل',
                description: '',
                image: '../../img/food/ترفل.jpg',
                price: 69,
                calories: 1070,
                selected: false,
            },
            {
                id: 133,
                title: 'روزيتو دجاج',
                description: '',
                image: '../../img/food/CHICKENRISOTTO42.JPG',
                price: 42,
                calories: 580,
                selected: false,
            },
            {
                id: 134,
                title: 'روزيتو روبيان',
                description: '',
                image: '../../img/food/SHRIMPRISOTTO48.JPG',
                price: 48,
                calories: 580,
                selected: false,
            },
    ],
  },
]
export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
